<template >
  <v-app id="axcess1">
    <div class="ma-0 pa-0">
      <!-- navbar section -->

      <v-app-bar
        v-show="!full_screen"
        id="main-head"
        fixed
        flat
        height="56"
        style="z-index: 5 !important"
        class="ma-0 pa-0 white"
      >
          <div class="pa-0 ma-auto">
            <v-app-bar-nav-icon
              class="d-block d-sm-none"
              @click="drawer = true"
            ></v-app-bar-nav-icon>
            <div
              class="pa-0 ma-0 d-none d-sm-block"
              :style="
                this.hover || this.pinned
                  ? 'margin-left:265px !important;'
                  : 'margin-left:90px !important;'
              "
            >
              <v-btn-toggle
                v-model="toggle_exclusive"
                mandatory
                class="pa-0 ma-0"
                style="height: 35px"
                group
                color="#046e8b"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      @click="changeThemeLight()"
                      class="ma-0 my-border"
                      style="height: 100%"
                    >
                      <v-icon small class="pa-0 ma-0"
                        >mdi-white-balance-sunny</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>{{ $t("lightmode") }}</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      @click="changeThemeDark()"
                      class="ma-0 my-border"
                      style="height: 100%"
                    >
                      <v-icon small class="pa-0 ma-0"
                        >mdi-moon-waxing-crescent</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>{{ $t("darkmode") }}</span>
                </v-tooltip>
              </v-btn-toggle>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="pa-0 ma-auto">
            <div class="pa-0 ma-0 mx-2 d-none d-sm-inline">
              <v-btn-toggle
                class="pa-0 ma-0 "
                v-model="toggle_exclusive_lg"
                mandatory
                white
                group
                color="#046e8b"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      class="ma-0 pa-2 my-border"
                      style="height: 100%"
                      @click="changeLanguageEn()"
                    >
                      <span class="">{{ $t("en") }}</span>
                    </v-btn>
                  </template>
                  <span>{{ $t("english") }}</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      class="ma-0 pa-2 my-border"
                      style="height: 100%"
                      @click="changeLanguageFr()"
                    >
                      <span class="">{{ $t("fr") }}</span>
                    </v-btn>
                  </template>
                  <span>{{ $t("french") }}</span>
                </v-tooltip>
              </v-btn-toggle>
            </div>
            <notificationPanel> </notificationPanel>
            <v-menu
              bottom
              v-if="user"
              min-width="250"
              rounded
              content-class="white elevation-0 my-border"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-btn icon x-large v-on="on" class="pa-0 ma-0 mx-1">
                  <v-avatar class="custom-color-accent" size="35">
                    <span class="text-uppercase"
                      >{{ user.first_name.charAt(0)
                      }}{{ user.last_name.charAt(0) }}</span
                    >
                  </v-avatar>
                </v-btn>
              </template>
              <v-card class="white">
                <v-list class="white">
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-avatar
                        class="custom-color-accent-text customoffwhite"
                        outlined
                        size="50"
                      >
                        <span class="headline pa-0 ma-0 text-uppercase"
                          >{{ user.first_name.charAt(0)
                          }}{{ user.last_name.charAt(0) }}</span
                        >
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ user.first_name }}
                        {{ user.last_name }}</v-list-item-title
                      >
                      <v-list-item-subtitle
                        >@{{ user.username }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
                <v-list class="white">
                  <v-list-item
                    :to="{ path: '/profile', query: { tab: 'settings' } }"
                  >
                    <v-list-item-title
                      ><v-icon>mdi-cog</v-icon>
                      {{ $t("settings") }}</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="logout()">
                    <v-list-item-title
                      ><v-icon>mdi-logout</v-icon>
                      {{ $t("logout") }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
      </v-app-bar>
      <!-- //navbar section -->

      <!-- sidebar section -->
      <div
        @mouseover="hover = true"
        @mouseleave="hover = false"
        @touchstart="pinned = !pinned"
        v-if="!full_screen"
        class="d-none d-sm-block"
      >
        <v-navigation-drawer
          permanent
          :expand-on-hover="!pinned"
          fixed
          class="white"
        >
          <div style="position:relative">
            <v-list dense nav class="transparent">
              <v-list-item class="items pa-0 ml-2">
                <v-list-item-title v-if="!this.hover && !this.pinned" class="clickable" @click="$router.push({ path: '/'}).catch(() => {});">
                  <v-img
                    class="ma-0 pa-0 mt-3"
                    v-if="!this.hover && !this.pinned"
                    height="46px"
                    width="46px"
                    :src="require('../assets/axcess1_small_logo_v2.png')"
                  ></v-img>
                </v-list-item-title>
                <v-list-item-title v-if="this.hover || this.pinned" class="clickable" @click="$router.push({ path: '/'}).catch(() => {});">
                  <v-img
                    class="ma-0 pa-0 mt-3"
                    v-if="this.hover || this.pinned"
                    height="46px"
                    :src="require('../assets/axcess1_logo_v2.png')"
                  ></v-img>
                </v-list-item-title>
                <v-spacer></v-spacer>
                <v-btn
                  class="pa-auto ma-auto text-center"
                  @click="pinSideBar()"
                  white
                  icon
                >
                  <v-icon class="ma-auto mt-2">mdi-pin</v-icon>
                </v-btn>
              </v-list-item>
              <v-list-item three-line class="items pa-0 ma-0 mb-1 px-3" link :to="{ path: '/profile', query: { tab: 'settings' } }" v-if="user">
                <v-list-item-avatar>
                  <v-avatar class="custom-color-accent" size="40">
                    <span class="text-uppercase"
                      >{{ user.first_name.charAt(0)
                      }}{{ user.last_name.charAt(0) }}</span
                    >
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <span class="subtitle font-weight-light"
                      >{{ $t("welcome") }},</span
                    >
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    <span class="title text--secondary"
                      >{{ user.first_name }} {{ user.last_name }}</span
                    >
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
            <v-list nav dense class="transparent" style="height: calc(100vh - 224px);overflow-y: auto;overflow-x: hidden;">
              <v-list-item
                class="items"
                :to="item.route"
                v-for="(item, i) in items"
                v-show="item.canRead"
                color="#046e8b"
                :key="i"
                link
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </v-list-item>

              <v-list-group
                prepend-icon="mdi-file-alert"
                color="#046e8b"
                v-if="canReadReports"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ $t("Reports") }}</v-list-item-title>
                </template>
                <div v-if="this.hover || this.pinned">
                  <v-list-item link :to="{ name: 'atipReport' }">
                    <v-list-item-title>{{ $t("atip") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'complaintReport' }">
                    <v-list-item-title>
                      {{ $t("complaintmanager") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'consultationReport' }">
                    <v-list-item-title>Consultations</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'courtReport' }">
                    <v-list-item-title>
                      {{ $t("courtmanager") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'snapshotReport' }">
                    <v-list-item-title>{{ $t("snapshot") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'RequestDashboard' }">
                    <v-list-item-title>
                      {{ $t("requestdashboard") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'TimeAnalysis' }">
                    <v-list-item-title>
                      {{ $t("timeanalysis") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'WeeklyReport' }">
                    <v-list-item-title>
                      {{ $t("weeklyreport") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'WorkloadReport' }">
                    <v-list-item-title>
                      {{ $t("workloadreport") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'tbsReport' }">
                    <v-list-item-title>{{ $t("tbs") }}</v-list-item-title>
                  </v-list-item>
                </div>
              </v-list-group>

              <v-list-group
                v-if="canReadAdminMenu"
                prepend-icon="mdi-tools"
                color="#046e8b"
              >
                <template v-slot:activator>
                  <v-list-item-title>Administration</v-list-item-title>
                </template>
                <div v-if="this.hover || this.pinned">
                  <v-list-item v-if="canReadUsers" link :to="{ name: 'users' }">
                    <v-list-item-title>
                      {{ this.$t("Users") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'finance' }">
                    <v-list-item-title>{{
                      this.$t("Finances")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    v-if="canReadPermissions"
                    :to="{ name: 'manageroles' }"
                  >
                    <v-list-item-title>Roles and Permissions</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'setupholidays' }">
                    <v-list-item-title>
                      {{ this.$t("Manage Holidays") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'activities' }">
                    <v-list-item-title>
                      {{ this.$t("Activities") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'taskrules'}">
                    <v-list-item-title>
                      {{ this.$t("taskrules") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'managefields' }">
                    <v-list-item-title>
                      {{ this.$t("Manage Fields") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'managesectors' }">
                    <v-list-item-title>
                      {{ this.$t("Manage Sectors") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'configuration' }">
                    <v-list-item-title>{{
                      $t("ManageLookupValues")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'reqType' }">
                    <v-list-item-title>
                      {{ this.$t("Manage Request Types") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'template' }">
                    <v-list-item-title>{{
                      this.$t("Templates")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'purgerequests' }">
                    <v-list-item-title>
                      {{ this.$t("Purge Request") }}
                    </v-list-item-title>
                  </v-list-item><!-- 
                  <v-list-item link :to="{ name: 'audittrail' }">
                    <v-list-item-title>{{
                      this.$t("AuditTrail")
                    }}</v-list-item-title>
                  </v-list-item> -->
                  <v-list-item link :to="{ name: 'logshistory' }">
                    <v-list-item-title>{{
                      this.$t("LogsHistory")
                    }}</v-list-item-title>
                  </v-list-item>
                </div>
              </v-list-group>
            </v-list>
            <v-list nav dense class="transparent">
              <v-divider></v-divider>
              <v-list-item class="items mt-2" @click="logout()" link>
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title> {{ $t("logout") }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-navigation-drawer>
      </div>
      <!-- //sidebar section -->
      <!-- sidebar section mobile-->
      <div :class="full_screen ? 'd-block' : 'd-block d-sm-none'">
        <v-navigation-drawer fixed temporary v-model="drawer" class="white">
            <v-list dense nav class="transparent">
                <v-list-item class="items">
                  <v-list-item-title class="clickable" @click="$router.push({ path: '/'}).catch(() => {});">
                    <v-img
                      class="ma-0 pa-0 mt-4"
                      height="46px"
                      :src="require('../assets/axcess1_logo_v2.png')"
                    ></v-img>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item two-line class="items pa-0 ma-0 mb-1 px-3" link :to="{ path: '/profile', query: { tab: 'settings' } }" v-if="user">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <span class="subtitle font-weight-light"
                        >{{ $t("welcome") }},</span
                      >
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      <span class="title text--secondary"
                        >{{ user.first_name }} {{ user.last_name }}</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
            <v-list nav dense class="transparent" style="height: calc(100vh - 218px);overflow-y: auto;overflow-x: hidden;">
              <v-list-item class="pa-0 ma-0 mb-2">
                <div class="pa-auto mr-auto">
                  <v-btn-toggle
                    class="pa-0 ma-0"
                    v-model="toggle_exclusive"
                    mandatory
                    style="height: 35px"
                    group
                    color="#046e8b"
                  >
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          @click="changeThemeLight()"
                          class="ma-0 my-border"
                          style="height: 100%"
                        >
                          <v-icon small class="pa-0 ma-0"
                            >mdi-white-balance-sunny</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{ $t("lightmode") }}</span>
                    </v-tooltip>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          @click="changeThemeDark()"
                          class="ma-0 my-border"
                          style="height: 100%"
                        >
                          <v-icon small class="pa-0 ma-0"
                            >mdi-moon-waxing-crescent</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{ $t("darkmode") }}</span>
                    </v-tooltip>
                  </v-btn-toggle>
                </div>
                <div class="pa-auto ml-auto">
                  <v-btn-toggle
                    class="pa-0 ma-0"
                    v-model="toggle_exclusive_lg"
                    mandatory
                    style="height: 35px"
                    group
                    color="#046e8b"
                  >
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          class="ma-0 my-border"
                          style="height: 100%"
                          @click="changeLanguageEn()"
                        >
                          <span class="">{{ $t("en") }}</span>
                        </v-btn>
                      </template>
                      <span>{{ $t("english") }}</span>
                    </v-tooltip>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          class="ma-0 my-border"
                          style="height: 100%"
                          @click="changeLanguageFr()"
                        >
                          <span class="">{{ $t("fr") }}</span>
                        </v-btn>
                      </template>
                      <span>{{ $t("french") }}</span>
                    </v-tooltip>
                  </v-btn-toggle>
                </div>
              </v-list-item>
              <v-list-item
                class="items"
                :to="item.route"
                v-for="(item, i) in items"
                v-show="item.canRead"
                :key="i"
                link
                color="#046e8b"
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </v-list-item>

              <v-list-group
                prepend-icon="mdi-file-alert"
                v-if="canReadReports"
                color="#046e8b"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ $t("Reports") }}</v-list-item-title>
                </template>
                <div>
                  <v-list-item link :to="{ name: 'atipReport' }">
                    <v-list-item-title>{{ $t("atip") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'complaintReport' }">
                    <v-list-item-title>
                      {{ $t("complaintmanager") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'consultationReport' }">
                    <v-list-item-title>Consultation</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'courtReport' }">
                    <v-list-item-title>
                      {{ $t("courtmanager") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'snapshotReport' }">
                    <v-list-item-title>{{ $t("snapshot") }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'RequestDashboard' }">
                    <v-list-item-title>
                      {{ $t("requestdashboard") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'TimeAnalysis' }">
                    <v-list-item-title>
                      {{ $t("timeanalysis") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'WeeklyReport' }">
                    <v-list-item-title>
                      {{ $t("weeklyreport") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'WorkloadReport' }">
                    <v-list-item-title>
                      {{ $t("workloadreport") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'tbsReport' }">
                    <v-list-item-title>{{ $t("tbs") }}</v-list-item-title>
                  </v-list-item>
                </div>
              </v-list-group>

              <v-list-group
                v-if="canReadAdminMenu"
                prepend-icon="mdi-tools"
                color="#046e8b"
              >
                <template v-slot:activator>
                  <v-list-item-title>Administration</v-list-item-title>
                </template>
                <div>
                  <v-list-item v-if="canReadUsers" link :to="{ name: 'users' }">
                    <v-list-item-title>
                      {{ this.$t("Users") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'finance' }">
                    <v-list-item-title>{{
                      this.$t("Finances")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    link
                    v-if="canReadPermissions"
                    :to="{ name: 'manageroles' }"
                  >
                    <v-list-item-title>Roles and Permissions</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'setupholidays' }">
                    <v-list-item-title>
                      {{ this.$t("Manage Holidays") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'activities' }">
                    <v-list-item-title>
                      {{ this.$t("Activities") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'taskrules'}">
                      <v-list-item-title> {{ this.$t("taskrules")}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'managefields' }">
                    <v-list-item-title>
                      {{ this.$t("Manage Fields") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'managesectors' }">
                    <v-list-item-title>
                      {{ this.$t("Manage Sectors") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'configuration' }">
                    <v-list-item-title>{{
                      $t("ManageLookupValues")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'reqType' }">
                    <v-list-item-title>
                      {{ this.$t("Manage Request Types") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'template' }">
                    <v-list-item-title>{{
                      this.$t("Templates")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{ name: 'purgerequests' }">
                    <v-list-item-title>
                      {{ this.$t("Purge Request") }}
                    </v-list-item-title>
                  </v-list-item><!-- 
                  <v-list-item link :to="{ name: 'audittrail' }">
                    <v-list-item-title>{{
                      this.$t("AuditTrail")
                    }}</v-list-item-title>
                  </v-list-item> -->
                  <v-list-item link :to="{ name: 'logshistory' }">
                    <v-list-item-title>{{
                      this.$t("LogsHistory")
                    }}</v-list-item-title>
                  </v-list-item>
                </div>
              </v-list-group>
            </v-list>
            <v-list nav class="transparent" dense>
              <v-divider></v-divider>
              <v-list-item @click="logout()" class="mt-2" link>
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title> {{ $t("logout") }}</v-list-item-title>
              </v-list-item>
            </v-list>
        </v-navigation-drawer>
      </div>
      <!-- //sidebar section mobile-->

      <!-- main section -->

      <v-sheet
        id="main-body"
        :class="
          pinned || full_screen
            ? 'overflow-y-hidden overflow-x-hidden gray pa-0 ma-0'
            : 'overflow-y-hidden overflow-x-hidden gray ml-0 ml-sm-9 pl-0 pl-sm-6'
        "
      >
        <div>
          <div
            class="
              main
              mt-3
              pt-12
              ma-0
              pa-0
              px-3
              pl-sm-8
              font-sans
              tracking-wide
              text-sm
            "
            id="main"
          >
            <!-- <auto-logout v-if="user" /> -->
            <span
              @click="
                $router.push({ path: '/profile', query: { tab: 'settings' } }).catch(() => {}); 
              "
              :items="breads"
              v-if="full_screen && user"
              class="pa-0 ma-0 custom-color-accent-text clickable"
              style="
                position: absolute;
                top: 6px;
                right: 14px;
                text-decoration: none;
              "
              >@{{ user.username }}</span
            >
            <v-breadcrumbs
              :items="breads"
              v-if="full_screen"
              class="pa-0 ma-0"
              style="
                margin-top: -8px !important;
                margin-bottom: -5px !important;
              "
            ></v-breadcrumbs>
            <v-row
              class="ma-0 pa-0 pb-1 full-toolbar"
              style="width: max-content"
            >
              <v-col class="ma-0 pa-0" v-if="full_screen" order="1">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-app-bar-nav-icon
                      v-on="on"
                      v-bind="attrs"
                      class="custom-color-accent-text"
                      @click="drawer = true"
                    >
                    </v-app-bar-nav-icon>
                  </template>
                  <span>{{ $t("opensidebar") }}</span>
                </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" order="2">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="$router.go(-1)"
                      icon
                      v-on="on"
                      v-bind="attrs"
                      class="pa-auto mx-0 custom-color-accent-text"
                    >
                      <v-icon class="pa-auto ma-auto">
                        mdi-arrow-left-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("goback") }}</span>
                </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" order="3">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="$router.go(1)"
                      icon
                      v-on="on"
                      v-bind="attrs"
                      class="pa-auto mx-0 custom-color-accent-text"
                    >
                      <v-icon class="ma-auto pa-auto">
                        mdi-arrow-right-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("gonext") }}</span>
                </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" order="4">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      class="pa-auto mx-0 custom-color-accent-text"
                      @click="reload"
                      icon
                      ><v-icon class="ma-auto pa-auto"
                        >mdi-refresh-circle</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>{{ $t("Refresh Page") }}</span>
                </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" v-if="full_screen" order="5">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="redirect_home"
                      icon
                      v-on="on"
                      v-bind="attrs"
                      class="pa-auto mx-0 custom-color-accent-text"
                    >
                      <v-icon class="ma-auto pa-auto"> mdi-home </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("home") }}</span>
                </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" order="6">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      class="pa-auto mx-0 custom-color-accent-text"
                      @click="setFullScreen"
                      icon
                      ><v-icon v-if="full_screen" class="ma-auto pa-auto"
                        >mdi-arrow-collapse-all</v-icon
                      ><v-icon v-else class="ma-auto pa-auto"
                        >mdi-arrow-expand-all</v-icon
                      ></v-btn
                    >
                  </template>
                  <span>{{ $t("fullscreen") }}</span>
                </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" order="7">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      class="pa-auto mx-0"
                      @click="setNotificationSound"
                      icon
                    >
                      <v-icon
                        v-if="muted"
                        class="ma-auto pa-auto text--disabled"
                        >mdi-volume-off</v-icon
                      >
                      <v-icon
                        v-else
                        class="ma-auto pa-auto custom-color-accent-text"
                        >mdi-volume-high</v-icon
                      ></v-btn
                    >
                  </template>
                  <span v-if="muted">{{ $t("unmute") }}</span>
                  <span v-else>{{ $t("mute") }}</span>
                </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" order="8">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      class="pa-auto mx-0"
                      @click="setMultitasking"
                      icon
                    >
                      <v-icon
                        v-if="!multitask"
                        class="ma-auto pa-auto text--disabled"
                        >mdi-window-restore</v-icon
                      >
                      <v-icon
                        v-else
                        class="ma-auto pa-auto custom-color-accent-text"
                        >mdi-window-restore</v-icon
                      ></v-btn
                    >
                  </template>
                  <span v-if="!multitask">{{ $t("enablemultitasking") }}</span>
                  <span v-else>{{ $t("disablemultitasking") }}</span>
                </v-tooltip>
              </v-col>
              <v-col class="ma-0 pa-0" v-if="full_screen" order="9">
                <notificationPanel> </notificationPanel>
              </v-col>
            </v-row>
            <router-view :key="componentKey" />
          </div>
        </div>
      </v-sheet>
      <!-- //main section -->

      <!-- alert section -->
      <alertVue
        :key="alertId"
        :alert.sync="alert"
        :alertText.sync="alertText"
        :alertType.sync="alertType"
      ></alertVue>
      <!-- //alert section -->
    </div>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
/* import AutoLogout from "../components/AutoLogout"; */
import notificationPanel from "../components/Notifications";
import alertVue from "@/components/Alert";
export default {
  name: "dashboard",

  data() {
    return {
      full_screen: false,
      taskSidebar: false,
      canReadPermissions: false,
      canReadReports: false,
      canReadAdminMenu: false,
      canReadUsers: false,
      canReadAddresses: false,
      canReadCourts: false,
      canReadComplaints: false,
      hover: false,
      toggle_exclusive_lg: null,
      toggle_exclusive: null,
      checkUser: true,
      pinned: false,
      muted: false,
      multitask: false,
      drawer: false,
      alert: false,
      alertText: "",
      alertType: "",
      alertId: "",
      componentKey: 99,
      items: [
        {
          title: "home",
          icon: "mdi-view-dashboard",
          route: "/",
          canRead: true,
        },
        {
          title: "profile",
          icon: "mdi-account",
          route: "/profile?tab=settings",
          canRead: true,
        },
        {
          title: "Addresses",
          icon: "mdi-account-group",
          route: "/address",
          canRead: this.$checkPermission("Contacts.Read"),
        },
        {
          title: "Consultations",
          icon: "mdi-clipboard-file",
          route: "/consultation",
          canRead: true,
        },
        {
          title: "Complaints",
          icon: "mdi-text-box-multiple-outline",
          route: "/complaint",
          canRead: this.$checkPermission("Complaints.Read"),
        },
        {
          title: "Courts",
          icon: "mdi-gavel",
          route: "/court",
          canRead: this.$checkPermission("Courts.Read"),
        },
        /* {
          title: "Correspondences",
          icon: "mdi-file-multiple",
          route: "/correspondence",
          canRead: this.$checkPermission("Correspondences.Read"),
        }, */
        { title: "Help", icon: "mdi-help-box", route: "/about", canRead: true },
      ],
      breads: [],
    };
  },
  components: {
    /* AutoLogout, */
    notificationPanel,
    alertVue,
  },

  created() {
    this.breads = this.$route.meta.bradcrumb;
    this.$store.dispatch("retriveUserInfo");
    this.$store.dispatch("configuration/retriveConfigurations");
    this.$store.dispatch("retriveTypes");
    this.$i18n.locale = localStorage.getItem("language");
    this.$root.$on("callAlert", (item) => {
      this.callAlert(item);
    });
    this.$root.$on("setTaskSidebar", (item) => {
      this.setTaskSidebar(item);
    });

    this.$root.$on("reloadPage", () => {
      this.reload();
    });
    this.$root.$on("setDetails", () => {
      this.setDetails();
    });
    this.$root.$on("setEditedItem", () => {
      this.setEditedItem();
    });
  },
  beforeMount() {
    this.setThemeToggle();
    this.setTheme();
    this.setLanguageToggle();
    this.setLanguage();
    this.setPinned();
    this.setFull();
    this.setMuted();
    this.setMultitask();
    },
  methods: {
    redirect_home() {
      this.$router.push({ name: "dashboard" }).catch(() => {});
    },
    setTaskSidebar(item) {
      this.taskSidebar = item;
    },
    setThemeToggle: function () {
      localStorage.getItem("theme") == "dark"
        ? ((this.toggle_exclusive = 1))
        : ((this.toggle_exclusive = 0));
    },
    setTheme: function () {
      localStorage.getItem("theme") == "dark"
        ? (this.$vuetify.theme.dark = true)
        : (this.$vuetify.theme.dark = false);
    },
    setLanguageToggle: function () {
      localStorage.getItem("language") == "en"
        ? ((this.toggle_exclusive_lg = 0))
        : ((this.toggle_exclusive_lg = 1));
    },
    setLanguage: function () {
      localStorage.getItem("language") == "en"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "fr");
    },
    setPinned: function () {
      if (localStorage.getItem("pinned") == "true") {
        this.$vuetify.pinned = true;
        this.pinned = true;
      } else {
        this.$vuetify.pinned = false;
        this.pinned = false;
      }
    },
    setFull: function () {
      if (localStorage.getItem("full") == "true") {
        this.$vuetify.full = true;
        this.full_screen = true;
      } else {
        this.$vuetify.full = false;
        this.full_screen = false;
      }
    },
    setMuted: function () {
      if (localStorage.getItem("muted") == "true") {
        this.$vuetify.muted = true;
        this.muted = true;
      } else {
        this.$vuetify.muted = false;
        this.muted = false;
      }
    },
    setMultitask: function () {
      if (localStorage.getItem("multitask") == "true") {
        this.$vuetify.multitask = true;
        this.multitask = true;
      } else {
        this.$vuetify.multitask = false;
        this.multitask = false;
      }
    },
    logout() {
      this.$store.dispatch("destroyToken").then(() => {
        this.$router.push({ name: "login" });
      });
    },
    changeThemeLight() {
      localStorage.setItem("theme", "light");
      this.$vuetify.theme.dark = false;
      this.reload();
    },
    changeThemeDark() {
      localStorage.setItem("theme", "dark");
      this.$vuetify.theme.dark = true;
      this.reload();
    },
    changeLanguageEn() {
      localStorage.setItem("language", "en");
      this.$i18n.locale = "en";
      this.reload();
    },
    changeLanguageFr() {
      localStorage.setItem("language", "fr");
      this.$i18n.locale = "fr";
      this.reload();
    },
    pinSideBar() {
      if (!this.pinned) {
        this.$vuetify.pinned = true;
        localStorage.setItem("pinned", this.$vuetify.pinned);
        this.pinned = true;
      } else {
        this.$vuetify.pinned = false;
        localStorage.setItem("pinned", this.$vuetify.pinned);
        this.pinned = false;
      }
    },
    setFullScreen() {
      if (!this.full_screen) {
        this.$vuetify.full = true;
        localStorage.setItem("full", this.$vuetify.full);
        this.full_screen = true;
      } else {
        this.$vuetify.full = false;
        localStorage.setItem("full", this.$vuetify.full);
        this.full_screen = false;
      }
    },
    setNotificationSound() {
      if (!this.muted) {
        this.$vuetify.muted = true;
        localStorage.setItem("muted", this.$vuetify.muted);
        this.muted = true;
      } else {
        this.$vuetify.muted = false;
        localStorage.setItem("muted", this.$vuetify.muted);
        this.muted = false;
      }
    },
    setMultitasking() {
      if (!this.multitask) {
        this.$vuetify.multitask = true;
        localStorage.setItem("multitask", this.$vuetify.multitask);
        this.multitask = true;
      } else {
        this.$vuetify.multitask = false;
        localStorage.setItem("multitask", this.$vuetify.multitask);
        this.multitask = false;
      }
      //this.reload();
    },
    async callAlert(item) {
      if (!this.muted) {
        let notifSound = new Audio(require('@/assets/notif.mp3'));
        notifSound.type = "audio/mp3";
        try {
          await notifSound.play();
        } catch (err) {
          console.log('Failed to play...' + err);
        }
      }
      this.alertText = item.text;
      this.alertType = item.type;
      this.alert = item.alert;
      this.alertId = Date.now();
    },
    reload() {
      this.setTaskSidebar(false);
      this.componentKey += 1;
      this.setThemeToggle();
      this.setTheme();
      this.setLanguageToggle();
      this.setLanguage();
      this.setPinned();
      this.setFull();
      this.setMuted();
      this.setMultitask();
    },
  },
  watch: {
    full_screen(val) {
      let element = document.getElementById("main");
      let element2 = document.getElementById("main-body");

      if (val) {
        if (this.pinned)
          element2.setAttribute("style", "margin-left: 0 !important");
        element.classList.remove("pt-12");
        element.classList.remove("pl-sm-8");
        element2.classList.remove("ml-sm-9");
        element2.classList.remove("pl-sm-6");
      } else {
        this.drawer = false;
        if (this.pinned)
          element2.setAttribute("style", "margin-left: 232px !important");
        else {
          element2.classList.add("ml-sm-9");
          element2.classList.add("pl-sm-6");
        }
        element.classList.add("pt-12");
        element.classList.add("pl-sm-8");
      }
    },
    user() {
      this.canReadReports = this.$checkPermission("Reports.Read");
      this.canReadAdminMenu = this.$checkPermission("AdminMenu.Read");
      this.canReadUsers = this.$checkPermission("Users.Read");
      this.canReadPermissions = this.$checkPermission("Permissions.Read");
    },
    taskSidebar(val) {
      let element = document.getElementById("main-body");
      if (!this.pinned || this.full_screen) {
        if (val) {
          element.setAttribute("style", "margin-right:250px !important;");
        } else {
          element.setAttribute("style", "");
        }
      } else {
        if (val) {
          element.setAttribute(
            "style",
            "margin-right:250px !important;margin-left:232px !important;"
          );
        } else {
          element.setAttribute("style", "margin-left:232px !important;");
        }
      }
    },
    pinned(val) {
      let element = document.getElementById("main-body");
      if (!this.taskSidebar) {
        if (val) {
          element.setAttribute("style", "margin-left:232px !important;");
        } else {
          element.setAttribute("style", "");
        }
      } else {
        if (val) {
          element.setAttribute(
            "style",
            "margin-left:232px !important;margin-right:250px !important;"
          );
        } else {
          element.setAttribute("style", "margin-right:250px !important;");
        }
      }
    },
    $route() {
      this.breads = this.$route.meta.bradcrumb;
      this.taskSidebar = false;
    },
  },
  computed: {
    ...mapState({
      user: "user",
    }),
  },
};
</script>
 
<style >
.v-select-list .theme--dark.v-list{background-color: #111 !important;}
.v-input__append-outer{
  margin: auto !important;
}
.v-input__append-inner{
  margin: auto !important;
}
.v-input__prepend-inner{
  margin: auto !important;
}
.v-application .my-border {border: 1px solid #7a7a7a52 !important}
.v-application .primary--text ,.v-application .primary--text fieldset{
    color: #046e8b !important;
    caret-color: #046e8b !important;
    border-color: #046e8b !important;
}
.v-application .v-text-field--outlined fieldset {
    border-color: #bbbbbb86 ;
}
.v-application .error--text fieldset {
    border-color: #ff5252 !important
}
.full-toolbar div {
  z-index: 4 !important;
}
.v-toolbar__content {
  padding: 0 !important;
}
.items,
.v-list-group__header {
  justify-content: left !important;
  padding-left: 20px !important;
}
#main {
  transition: 0.3s all ease;
}
#main-body {
  transition: 0.3s all ease;
  min-height: 100vh !important;
}
.notif_full {
  position: fixed !important;
  right: 75px;
  top: 1px;
  z-index: 6;
}
#main-footer {
  position: fixed !important;
  left: 0;
  bottom: 0;
  z-index: 6;
}
#sidebar-footer {
  position: fixed !important;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.v-toolbar__content {
  padding: 0;
}
</style>