<template>
  <v-menu :close-on-content-click="false" offset-y disabled content-class="elevation-0 my-border">       
    <template v-slot:activator="{ on }">
      <v-btn 
        icon
        v-on="on">
        <v-badge
          overlap 
          disabled
          color="custom-color-accent-text">
          <template v-slot:badge>
            6
        </template>

        <v-avatar size="30">
            <v-icon>mdi-bell</v-icon>
        </v-avatar>
        </v-badge>
      </v-btn>
    </template>
    <v-card class="ma-0 pa-0" width="300px" height="100%">
      <v-list three-line  class="ma-0 pa-0  white">
        <v-row class="custom-color-accent-text customlightgray accent-4 text-center ma-0 pa-0">
          <v-col cols="12" class=" font-weight-light "><span>Notifications</span></v-col>
        </v-row>
        <v-divider></v-divider>
        <div  style="height:360px !important;overflow-y:auto;">
          <template v-for="(item,i)  in notifications" >
            <v-list-item :key="i" class="clickable hoverable">
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
                <v-list-item-subtitle v-html="item.desc"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="'d-'+i"></v-divider>
          </template>
        </div>
         <v-divider></v-divider>
         <v-row class="text-center ma-0 pa-0">
          <v-col cols="12" class="custom-color-accent-text customlightgray pa-0 ma-0 clickable"><v-btn rounded class="elevation-0 transparent custom-color-accent-text">View More</v-btn></v-col>
        </v-row>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: "dashboard",
  
  data() {
    return {
        broad:'',
        notifications: [
        { id: 1, title: 'New Request Assignement', desc: 'You have been assigned to a new request by Saidana Malek' },
        { id: 2, title: 'New Task Assignement', desc: 'You have been assigned a new task to do by Punit Patel' },
        { id: 3, title: 'Task Late', desc: 'You have not finished the task \'Update request status\' in time' },
        { id: 4, title: 'New Request Assignement', desc: 'You have been assigned to a new request by Punit Patel' },
      ],
    };
  },
  mounted(){
   /*  window.Echo.channel('channel').listen('Notify',(e)=>{
      this.broad=e.notify;
      alert(this.broad)
    }) */
  }
}
</script>